import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Purchase } from 'shared/models/Purchase'
import { isNil } from 'lodash'
import SaleRowItem from './SaleRowItem'
import { observer } from 'mobx-react'
import { User } from 'shared/models/User'
import { getFeesAmountGenerator } from 'shared/utility'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay } from 'components/UI/Modal/Modal'
import backgroundImage from '../../../assets/images/goldenBg1.svg'
import PurchaseStore from 'containers/Purchases/PurchaseStore'
import SaleDiscountInfo from './SaleDiscountInfo'

type SaleDetailsProps = {
  sale: Purchase
  loggedUser: User
  isDetailsOpened?: boolean
  saleDesktopDetailsOpened?: Purchase | null
  index: number
  store: PurchaseStore
}

const SaleItemDetails = ({
  sale,
  loggedUser,
  isDetailsOpened,
  saleDesktopDetailsOpened,
  index,
  store,
}: SaleDetailsProps) => {
  const { t } = useTranslation()
  const [openPackageDetailsModal, setOpenPackageDetailsModal] = useState(false)
  const [modalType, setModalType] = useState<'discount' | 'package' | null>(null)
  const discountInfo = store.getSaleDiscountInfo(sale)

  const discountMessage = t(
    'This sale has a discount because the client has purchased {{count}} photos (some of them may be from other photographers) and therefore a discount of {{percentage}}% was applied.',
    {
      count: sale.totalLineItemsCount,
      percentage: discountInfo.discountPercentage,
    }
  )

  if (!isNil(isDetailsOpened) && !isDetailsOpened) {
    return null
  }
  if (!isNil(saleDesktopDetailsOpened) && !saleDesktopDetailsOpened) {
    return null
  }

  const packageSaleExplanation = t(
    'packageSaleExplanation',
    `In this type of sale, a user purchases all their photos from an event at a fixed price of $${
      sale.lineItems[0].album.event?.defaultPackagePrice ||
      sale.lineItems[0].album.defaultPackagePrice
    }, which is more convenient than buying them individually.

The total package price is distributed among the photographers based on the number of photos they sold within the package. Therefore, you may notice that some of your photos have been sold at a lower price than the individual photo price. This is due to the discount applied for purchasing a package, which improves the user experience and helps increase total sales.`
  )

  const handleClosePackageDetailsModal = () => {
    setModalType(null)
    setOpenPackageDetailsModal(false)
  }

  const discounts = store.getSaleDiscountAmount(sale)
  const subTotal = store.getSaleSubTotal(sale)
  const totalEarnings = store.getSaleTotalEarnings(sale, loggedUser.id)

  return (
    <div className="flex flex-col w-full gap-1">
      <div className="flex flex-col w-full gap-1 md:flex md:mt-4 md:py-2 md:px-3 md:rounded-lg md:bg-white">
        <div className="hidden md:flex md:items-center md:gap-1 md:pb-3">
          <span className="md:flex font-semibold text-xl text-lumepic-light_black">
            {t('Sale')} #{index}
          </span>
          <span className="text-lumepic-dark_grey">({sale.photographs.length})</span>
        </div>

        {sale.lineItems.map((lineItem, index) => (
          <SaleRowItem key={index} lineItem={lineItem} />
        ))}
      </div>

      <div className="flex flex-col md:my-6 md:py-2 md:px-3 md:rounded-lg md:bg-white">
        <span className="font-semibold text-xl text-lumepic-light_black pt-2 pb-3">
          {t('Sale details')}
        </span>
        <span className="flex justify-between">
          <p className="text-base font-semibold">{t('Subtotal')}</p>
          <p className="text-base font-semibold">${subTotal}</p>
        </span>

        <div>
          <div className="flex justify-between">
            {(discounts > 0 || !isNil(sale.lineItems[0].packageType)) && (
              <SaleDiscountInfo
                discountMessage={
                  !isNil(sale.lineItems[0].packageType) ? packageSaleExplanation : discountMessage
                }
              />
            )}

            <p className="text-lumepic-grey font-semibold text-sm">
              {discounts && discounts.toFixed(2) !== '0.00' ? `- $${discounts.toFixed(2)}` : '-'}
            </p>
          </div>

          <div className="flex justify-between">
            <p className="text-lumepic-grey font-semibold text-sm">{t('Service')} </p>

            <p className="text-lumepic-grey font-semibold text-sm">
              {getFeesAmountGenerator(loggedUser.id, sale).toFixed(2) !== '0.00'
                ? `- $${getFeesAmountGenerator(loggedUser.id, sale).toFixed(2)}`
                : '-'}
            </p>
          </div>
          <div className="border-t my-2" />
          <div className="flex justify-between pb-3 ">
            <p className="font-extrabold text-lg">{t('Total')} </p>
            <p className="font-extrabold text-lg">
              {sale.currency.toUpperCase()} ${totalEarnings.toFixed(2)}
            </p>
          </div>
        </div>
      </div>

      <Modal
        displayCloseIcon={false}
        opened={openPackageDetailsModal}
        onCancel={handleClosePackageDetailsModal}
        buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      >
        <div className="w-full">
          <div className="relative overflow-hidden h-14 rounded-t-md">
            <img src={backgroundImage} className="w-full" alt="background" />
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="absolute text-xl cursor-pointer top-2 right-2"
              onClick={handleClosePackageDetailsModal}
            />
          </div>
          <div className="relative h-auto">
            <div className="absolute w-full text-center -top-6">
              <div className="flex justify-center">
                <h3 className="mb-0 text-center">
                  <span className="py-1 px-2 text-2xl font-medium bg-white rounded-md shadow-md">
                    {modalType === 'package' ? t('Package Sale') : t('Discount Information')}
                  </span>
                </h3>
              </div>
            </div>
          </div>
          <div className="w-full bg-bg_details rounded-b-md pt-4 pb-0.5">
            <div className="md:mx-2.5 mt-2.5 mb-3 p-3.5 rounded-md bg-white">
              {modalType === 'package' ? (
                <p dangerouslySetInnerHTML={{ __html: packageSaleExplanation }} />
              ) : (
                <p>{discountMessage}</p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default observer(SaleItemDetails)
