import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import Button from 'components/UI/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

type SaleDiscountInfoProps = {
  discountMessage: string
}

const SaleDiscountInfo = ({ discountMessage }: SaleDiscountInfoProps) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="w-3/4 relative">
      <div className="flex items-center gap-2">
        <p className="text-lumepic-grey font-semibold text-sm">{t('Discounts')}</p>

        <Tooltip title={t('Click "More Info" for details')} arrow>
          <FontAwesomeIcon icon={faInfoCircle} className="text-lumepic-light_black" />
        </Tooltip>

        <Button
          btnType="TertiaryAction"
          onClick={() => setExpanded(!expanded)}
          extraStyle="text-sm"
        >
          {expanded ? t('Hide Info') : t('More Info')}
        </Button>
      </div>

      {expanded && (
        <div className="mt-2 p-2 bg-gray-100 rounded-md text-sm">
          <p>{discountMessage}</p>
        </div>
      )}
    </div>
  )
}

export default SaleDiscountInfo
