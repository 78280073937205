import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { isNil } from 'lodash'
import { AxiosResponse } from 'axios'
import AlbumInterface from './RequestInterfaces/Album/Album.interface'
import Album from '../shared/models/Album'
import PhotographInterface from './RequestInterfaces/Photograph/Photograph.interface'
import { Photograph } from '../shared/models/Photograph'
import FeedInterface from './RequestInterfaces/Feed/Feed.interface'
import { Paginated } from './RequestInterfaces/Paginated'
import { PhotographerProfileInfo } from 'shared/models/PhotographerProfileInfo'
import EventInterface from './RequestInterfaces/Event/Event.interface'
import { Event } from 'shared/models/Event'
import { Tag } from 'shared/models/Tag'
import TagInterface, { TagKeys } from './RequestInterfaces/Tag/Tag.interface'
import Tracker from 'shared/tracking'
import { ApplicationHeaders } from '../shared/util/constants'

export type FetchFeedAlbumOptions = {
  isSearching?: boolean
  countryCode?: string
  albumIds?: string[]
  dateFrom?: string | null
  dateTo?: string | null
  locationId?: string
  activityId?: string
  eventId?: string
  ownerId?: string
  isFilteringByFaceRecognition?: boolean
  tagValue?: string | null
  includeHiddenEventAlbums?: boolean
  limit: number
  offset: number
  order?: FeedOrderQueryParams
  password?: string
}

type FetchFeedPhotographOptions = {
  filters?: {
    tagIds?: string[]
    tagValue?: string
    recognitionImageUrl?: string
    dateFrom?: string | null
    dateTo?: string | null
  }
  pagination: {
    limit: number
    skip: number
  }
  userId?: string
}

type FeedAlbumResponse = {
  albums: Album[]
  count: number
  isSearching?: boolean
}

export enum FeedOrderFields {
  CREATED_AT = 'CREATED_AT',
  TAKEN_DATE = 'TAKEN_DATE',
  RATIO = 'RATIO',
  PHOTOGRAPHS_SOLD = 'PHOTOGRAPHS_SOLD',
  NET_SALES_AMOUNT_IN_USD = 'NET_SALES_AMOUNT_IN_USD',
  SALES_COUNT = 'SALES_COUNT',
  ACTIVITY = 'ACTIVITY',
}

export enum FeedOrderSorts {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FeedOrderQueryParams = {
  field: FeedOrderFields
  sort: FeedOrderSorts
}

export type FeedAlbumsQueryParams = {
  order?: FeedOrderQueryParams[]
  pagination: {
    limit: Number
    skip: Number
  }
  filters: {
    countryCode?: string
    albumIds?: string[]
    locationId?: string
    activityId?: string
    eventId?: string
    dateFrom?: string | null
    dateTo?: string | null
    ownerId?: string
    disablePagination?: boolean
    includeHiddenEventAlbums?: boolean
  }
}

class FeedService {
  fetchFeedAlbums(options: FetchFeedAlbumOptions): Promise<FeedAlbumResponse> {
    const {
      countryCode,
      albumIds,
      limit,
      offset,
      order,
      locationId,
      activityId,
      eventId,
      dateFrom,
      dateTo,
      ownerId,
      isFilteringByFaceRecognition,
      tagValue,
      includeHiddenEventAlbums,
      password,
    } = options
    const queryParams: FeedAlbumsQueryParams = {
      order: [
        {
          field: order?.field || FeedOrderFields.TAKEN_DATE,
          sort: order?.sort || FeedOrderSorts.DESC,
        },
      ],
      pagination: {
        limit: Number(limit),
        skip: Number(offset),
      },
      filters: {},
    }
    if (!isNil(countryCode)) {
      queryParams.filters.countryCode = countryCode
    }
    if (!isNil(albumIds)) {
      queryParams.filters.albumIds = albumIds
    }
    if (!isNil(locationId)) {
      queryParams.filters.locationId = locationId
    }
    if (!isNil(activityId)) {
      queryParams.filters.activityId = activityId
    }
    if (!isNil(eventId)) {
      queryParams.filters.eventId = eventId
    }
    if (!isNil(dateFrom)) {
      queryParams.filters.dateFrom = dateFrom
    }
    if (!isNil(dateTo)) {
      queryParams.filters.dateTo = dateTo
    }
    if (!isNil(ownerId)) {
      queryParams.filters.ownerId = ownerId
    }
    if (isFilteringByFaceRecognition || (!isNil(tagValue) && tagValue !== '')) {
      queryParams.filters.disablePagination = true
    }
    if (includeHiddenEventAlbums) {
      queryParams.filters.includeHiddenEventAlbums = includeHiddenEventAlbums
    }
    return axios

      .get(paths.FEED, {
        params: queryParams,
        headers: { [ApplicationHeaders.Password]: password },
      })
      .then((res: AxiosResponse<FeedInterface>) => {
        const { count, albums } = res.data

        const albumModels = albums.map((a) => Album.init(a))
        return {
          count,
          albums: albumModels,
        }
      })
  }

  fetchPhotographerProfileInfo(alias: string): Promise<PhotographerProfileInfo> {
    return axios
      .get(paths.feedPhotographerProfileByAlias(alias))
      .then((res: AxiosResponse<PhotographerProfileInfo>) => {
        return res.data
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchEventDetailsInformationByLandingPath(eventLandingPath: string): Promise<Event> {
    return axios
      .get(paths.feedEventDetailsByLandingPath(eventLandingPath))
      .then((res: AxiosResponse<EventInterface>) => {
        const data = res.data
        return Event.init(data)
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchTag(tagValue: string): Promise<Tag | null> {
    return axios
      .get(paths.tagByValue(tagValue))
      .then((res: AxiosResponse<TagInterface | null>) => {
        const data = res.data
        if (!isNil(data)) {
          return Tag.init(data)
        } else return null
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAlbumDetails(albumId: string): Promise<Album> {
    return axios
      .get(paths.albumDetails(albumId))
      .then((res: AxiosResponse<AlbumInterface>) => {
        const data = res.data
        return Album.init(data)
      })
      .catch(parseAxiosErrorResponse)
  }
  fetchAlbumTags(albumId: string): Promise<Tag[]> {
    return axios
      .get(paths.albumTags(albumId))
      .then((res: AxiosResponse<TagInterface[]>) => {
        const data = res.data
        return data.map((tag) => Tag.init(tag))
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchPhotograph(albumId: string, photographId: string, password?: string): Promise<Photograph> {
    return axios
      .get(paths.albumPhotograph(albumId, photographId), {
        headers: { [ApplicationHeaders.Password]: password },
      })
      .then((res: AxiosResponse<PhotographInterface>) => {
        const data = res.data
        return Photograph.init(data)
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAlbumPhotographs(
    albumId: string,
    limit: number,
    skip: number,
    tagId?: string,
    tagValue?: string,
    tagKey?: TagKeys,
    recognitionImageUrl?: string,
    dateFrom?: string,
    dateTo?: string,
    userId?: string,
    password?: string
  ): Promise<Paginated<Photograph>> {
    const options: FetchFeedPhotographOptions = {
      pagination: {
        limit: Number(limit),
        skip: Number(skip),
      },
      userId: userId,
      filters: {},
    }

    if (!isNil(tagId)) {
      options.filters = { ...options.filters, tagIds: [tagId] }
    }

    if (!isNil(dateFrom)) {
      options.filters = { ...options.filters, dateFrom }
    }

    if (!isNil(dateTo)) {
      options.filters = { ...options.filters, dateTo }
    }

    if (!isNil(tagValue) && tagValue !== '') {
      options.filters = { tagValue }
      Tracker.filterByTag(tagKey, userId, albumId, undefined)
    }

    if (!isNil(recognitionImageUrl)) {
      options.filters = { ...options.filters, recognitionImageUrl }
      Tracker.filterByFaceRecognition(userId, albumId, undefined)
    }
    return axios
      .get(paths.albumPhotographs(albumId), {
        params: options,
        headers: { [ApplicationHeaders.Password]: password },
      })
      .then((res: AxiosResponse<Paginated<PhotographInterface>>) => {
        const result = res.data
        const models = result.items.map((p) => Photograph.init(p))
        return {
          items: models,
          count: result.count,
          tag: result.tag,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  async downloadFreePhotograph(photographId: string) {
    try {
      const response = await axios.get(paths.downloadFreePhotograph(photographId))
      const imageUrl = response.data.url

      const imageBlob = await fetch(imageUrl).then((r) => r.blob())
      return { blob: imageBlob, success: true }
    } catch (error) {
      return { error, success: false }
    }
  }
}

export default FeedService
