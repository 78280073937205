import React from 'react'
import { faImage } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import photographerImage from '../../../assets/icons/v2/photographer-gold.svg'
import { useTranslation } from 'react-i18next'
import { faPersonRunning } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react'
import ConfigService, { AssetNames } from 'config'

const StatsBar = () => {
  const { t } = useTranslation()

  return (
    <div className="w-full h-full bg-white relative">
      <div className="absolute z-20 w-full h-full flex justify-around items-center">
        <div className="flex flex-col w-1/3 items-center gap-0.5 lg:gap-2">
          <FontAwesomeIcon icon={faImage} className="text-5xl lg:text-7xl text-primary mb-1.5" />
          <span className="font-medium text-white text-2xl mb-0">10K+</span>
          <p className="mb-0 text-bg_details font-medium text-sm md:text-base text-center">
            {t('Published albums')}
          </p>
        </div>
        <div className="flex flex-col w-1/3 items-center">
          <img
            src={photographerImage}
            alt="Photographer"
            className="h-14 sm:h-10 md:h-14 lg:h-20"
          />
          <span className="font-medium text-white text-2xl md:mb-2">3K+</span>
          <p className="mb-0 px-1 text-bg_details font-medium text-sm md:text-base text-center">
            {t('Active photographers')}
          </p>
        </div>
        <div className="flex flex-col items-center w-1/3 gap-0.5 lg:gap-2">
          <FontAwesomeIcon
            icon={faPersonRunning}
            className="text-5xl lg:text-7xl text-primary mb-1.5"
          />
          <span className="font-medium text-white text-2xl mb-0">55+</span>
          <p className="mb-0 text-bg_details font-medium text-sm md:text-base text-center">
            {t('Photographed sports')}
          </p>
        </div>
      </div>
      <img
        src={ConfigService.getAsset(AssetNames.LANDING_METRICS)}
        className="object-cover object-center w-full h-landing3 md:h-full z-10"
        style={{ boxShadow: '0px 0px 50px 40px rgba(0,0,0,0.1)' }}
        alt="Background of images"
      />
    </div>
  )
}

export default observer(StatsBar)
