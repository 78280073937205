import { User } from './User'
import { Photograph } from './Photograph'
import { Payment, PaymentStatus } from './Payment'
import { Currency } from '../util/Currency'
import { PurchaseLineItem } from './PurchaseLineItem'

export enum PurchaseStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  CANCELLED = 'cancelled',
}

export class Purchase {
  id: string

  value: number // totalPrice without discounts.

  totalPrice: number // value - discounts.

  discountTotal: number

  status: PurchaseStatus

  owner: User

  ownerId: string

  seller: User

  sellerId: string

  lineItems: PurchaseLineItem[]

  currency: Currency

  paymentUrl?: string

  preferenceId?: string

  payments: Payment[]

  createdAt: Date

  updatedAt: Date

  deletedAt?: Date

  get approvedPayments(): Payment[] {
    return this.payments.filter((payment) => payment.status !== PaymentStatus.APPROVED)
  }

  get photographs(): Photograph[] {
    return this.lineItems.map((lineItem) => lineItem.photographs).flat()
  }

  get totalLineItemsCount(): number {
    return this.lineItems.reduce((acc, lineItem) => acc + lineItem.photographs.length, 0)
  }
}
