import React, { Suspense, useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import i18next from 'i18next'

import './App.scss'
import Layout from './hoc/Layout/Layout'
import storesContext from './providers/storesContext'
import Router from './routing'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions } from './themes/mui'
import Banner from './components/UI/Banner'
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk'
import ConfigService, { ConfigKeys } from './config'
import { isNil } from 'lodash'
import { useLoadScript } from '@react-google-maps/api'
import CustomLoader from 'components/UI/Spinner/CustomLoader'
import Maintenance from './containers/Maintenance'
import { ZapierBotIdEs } from 'shared/util/constants'
import { ZapierBotIdEn } from 'shared/util/constants'

const App = () => {
  const ldClient = useLDClient()
  // Load maps script to have google.maps.Geocoder available
  const mapsScript = useLoadScript({
    googleMapsApiKey: ConfigService.getValue(ConfigKeys.GOOGLE_MAPS_API_KEY),
    libraries: ['places'],
  })
  const { authStore, locationStore, activityStore } = useContext(storesContext)!
  const isAuthenticated = authStore.isAuthenticated()
  const isPhotographer = isAuthenticated && authStore.getLoggedUser().isPhotographer
  const isAthlete = isAuthenticated && authStore.getLoggedUser().isAthlete

  // TEMPORARY REDIRECT: PRODUCTION REBRANDING
  useEffect(() => {
    if (window.location.host === 'www.spotted.com.uy') {
      window.location.href =
        'https://www.lumepic.com' + window.location.pathname + window.location.search
    }
  }, [])

  // TEMPORARY REDIRECT: STAGING REBRANDING
  useEffect(() => {
    if (window.location.host === 'www.staging.spotted.com.uy') {
      window.location.href =
        'https://www.staging.lumepic.com' + window.location.pathname + window.location.search
    }
  }, [])

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-PC38BQ5' })
    ReactGA.initialize('G-X8ZQSNF95B')
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const utmSource = params.get('utm_source')
    const utmCampaign = params.get('utm_campaign')

    if (utmSource) {
      localStorage.setItem('utm_source', utmSource)
    }
    if (utmCampaign) {
      localStorage.setItem('utm_campaign', utmCampaign)
    }
  }, [])

  const loadZapierChatbot = () => {
    const zapierScript = document.createElement('script')
    zapierScript.type = 'module'
    zapierScript.async = true
    zapierScript.className = 'relative z-10'
    zapierScript.src =
      'https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js'

    const updateChatbot = () => {
      const existingChatbot = document.querySelector('zapier-interfaces-chatbot-embed')
      if (existingChatbot) {
        document.body.removeChild(existingChatbot)
      }

      const chatbotElement = document.createElement('zapier-interfaces-chatbot-embed')
      chatbotElement.setAttribute('is-popup', 'true')

      const chatbotId = i18next.language === 'en' ? ZapierBotIdEn : ZapierBotIdEs
      chatbotElement.setAttribute('chatbot-id', chatbotId)
      document.body.appendChild(chatbotElement)
    }

    zapierScript.onload = updateChatbot

    i18next.on('languageChanged', () => {
      updateChatbot()
    })

    document.body.appendChild(zapierScript)
  }

  useEffect(() => {
    loadZapierChatbot()

    return () => {
      const chatbotElement = document.querySelector('zapier-interfaces-chatbot-embed')
      if (chatbotElement) {
        document.body.removeChild(chatbotElement)
      }
      const zapierScript = document.querySelector('script[src*="zapier-interfaces"]')
      if (zapierScript) {
        document.body.removeChild(zapierScript)
      }
    }
  }, [])

  const muiTheme = createTheme(themeOptions)
  // wait until ldClient is ready
  const appIsReady =
    !isNil(ldClient) &&
    mapsScript.isLoaded &&
    !locationStore.isLoadingLocations &&
    !activityStore.isLoading
  if (!appIsReady) {
    return <CustomLoader />
  }

  const appIsInMaintenance = false

  if (appIsInMaintenance) {
    return <Maintenance />
  }

  return (
    <div className="bg-primary">
      <ThemeProvider theme={muiTheme}>
        <Layout>
          <Suspense fallback={<p>Loading...</p>}>
            <Router isPhotographer={isPhotographer} isAthlete={isAthlete} />
          </Suspense>
        </Layout>
        <Banner />
      </ThemeProvider>
    </div>
  )
}

export default withLDProvider({
  clientSideID: ConfigService.getValue(ConfigKeys.LAUNCHDARKLY_CLIENT_SIDE_ID),
})(withRouter(observer(App)))
