export type NetworkSpeedClass = 'critical' | 'slow' | 'medium' | 'fast' | 'unknown'

interface NetworkSpeedInfo {
  speed: number // in Mbps
  classification: NetworkSpeedClass
  lastChecked: Date
}

// Add type definition for NetworkInformation
interface NetworkInformation extends EventTarget {
  readonly downlink: number
  readonly effectiveType: 'slow-2g' | '2g' | '3g' | '4g'
  readonly rtt: number
  readonly saveData: boolean
  readonly type:
    | 'bluetooth'
    | 'cellular'
    | 'ethernet'
    | 'none'
    | 'wifi'
    | 'wimax'
    | 'other'
    | 'unknown'
}

declare global {
  interface Navigator {
    connection?: NetworkInformation
  }
}

let cachedSpeedInfo: NetworkSpeedInfo | null = null
const CACHE_DURATION = 5 * 1000 // 5 seconds for more frequent updates

// TODO: Validate this classification
const classifySpeed = (speedMbps: number): NetworkSpeedClass => {
  if (speedMbps < 1) return 'critical'
  if (speedMbps < 5) return 'slow'
  if (speedMbps < 20) return 'medium'
  return 'fast'
}

const getSpeedFromEffectiveType = (effectiveType: string): number => {
  // Approximate speeds based on effective connection type
  switch (effectiveType) {
    case 'slow-2g':
      return 0.1 // 100 Kbps
    case '2g':
      return 0.384 // 384 Kbps
    case '3g':
      return 2 // 2 Mbps
    case '4g':
      return 10 // 10 Mbps
    default:
      return 1 // Default to 1 Mbps
  }
}

export const measureNetworkSpeed = async (): Promise<NetworkSpeedInfo> => {
  // Return cached result if available and recent
  if (
    cachedSpeedInfo &&
    new Date().getTime() - cachedSpeedInfo.lastChecked.getTime() < CACHE_DURATION
  ) {
    return cachedSpeedInfo
  }

  try {
    const connection = navigator.connection
    let speed = 1 // Default to 1 Mbps

    if (connection) {
      // Use downlink if available (most accurate)
      if (connection.downlink) {
        speed = connection.downlink
      } else {
        // Fallback to effective type estimation
        speed = getSpeedFromEffectiveType(connection.effectiveType)
      }

      // Adjust speed based on connection type
      if (connection.type === 'wifi' || connection.type === 'ethernet') {
        speed = Math.max(speed, 2) // Minimum 2 Mbps for wifi/ethernet
      }
    }

    cachedSpeedInfo = {
      speed: Number(speed.toFixed(2)),
      classification: classifySpeed(speed),
      lastChecked: new Date(),
    }

    return cachedSpeedInfo
  } catch (error) {
    return {
      speed: 1, // Default to 1 Mbps instead of 0
      classification: 'medium',
      lastChecked: new Date(),
    }
  }
}
