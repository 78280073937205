import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  FormControlLabel,
  Button,
} from '@mui/material'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UploadImageToAlbumStore, { ProgressInfo } from '../UploadImageToAlbumStore'
import ImageUploadProgress from './ImageUploadProgress'
import { formatBits } from '../../../../../../shared/utility'
import { faCircleExclamation, faXmark, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { isNil } from 'lodash'
import UploadMessagesBanner from '../components/UploadMessagesBanner'

interface Column {
  id: 'name' | 'extension' | 'size' | 'status'
  label: string
  minWidth?: number
  format?: any
}

const columns: readonly Column[] = [
  { id: 'name', label: 'File Name', minWidth: 170 },
  { id: 'extension', label: 'Extension', minWidth: 100 },
  { id: 'size', label: 'Size', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
]

type ImageUploadTableProps = {
  store: UploadImageToAlbumStore
  isOnline: boolean
}

const getErrorMessage = (info: any) => {
  if (!info.errorType) return 'Upload failed'

  switch (info.errorType) {
    case 'network':
      return `Network error: ${info.errorMessage}`
    case 'permission':
      return 'Permission denied'
    case 'server':
      return 'Server error'
    default:
      return info.errorMessage || 'Unknown error'
  }
}

const getNetworkSpeedLabel = (classification?: string, speed?: number) => {
  const speedLabel = speed ? `${speed.toFixed(1)} Mbps` : ''
  switch (classification) {
    case 'critical':
      return `${speedLabel} (Critical Connection)`
    case 'slow':
      return `${speedLabel} (Slow Connection)`
    case 'medium':
      return `${speedLabel} (Medium Connection)`
    case 'fast':
      return `${speedLabel} (Fast Connection)`
    default:
      return speedLabel || ''
  }
}

const ImageUploadTable = ({ store, isOnline }: ImageUploadTableProps) => {
  const { t } = useTranslation()
  const { progressInfos, images } = store
  const currentImageIndex = store.getCurrentUploadingImageIndex()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [photographLines, setPhotographLines] = useState<ProgressInfo[] | null>(null)
  const [showCompleted, setShowCompleted] = useState(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const defaultLabelDisplayedRows = ({ from, to, count }) => {
    return `${from}–${to} ${t('of')} ${count !== -1 ? count : `more than ${to}`}`
  }

  let totalUploadSize = 0
  let uploadedSuccessfullyCount = 0
  let uploadedWithErrorsCount = 0
  let totalProcessedCount = 0
  let uploadedSuccessfullySize = 0
  let uploadedWithErrorsSize = 0

  for (const progressInfo of progressInfos) {
    totalUploadSize += progressInfo.size

    // Only count as success if it's 100% AND not errored AND not retrying
    if (progressInfo.percentage === 100 && !progressInfo.error && !progressInfo.retrying) {
      uploadedSuccessfullyCount++
      uploadedSuccessfullySize += progressInfo.size
    }

    // Count as error if explicitly marked as error OR if failed during upload
    if (progressInfo.error || (progressInfo.percentage === 100 && progressInfo.retrying)) {
      uploadedWithErrorsCount++
      uploadedWithErrorsSize += progressInfo.size
    }

    // Count as processed if either completed successfully or errored
    if (
      (progressInfo.percentage === 100 && !progressInfo.retrying && !progressInfo.error) ||
      progressInfo.error
    ) {
      totalProcessedCount++
    }
  }

  const progressInfosDisplayed: ProgressInfo[] =
    !store.allImagesUploaded() || isNil(photographLines) ? progressInfos : photographLines

  const getFilteredProgressInfos = () => {
    const baseProgressInfos =
      !store.allImagesUploaded() || isNil(photographLines) ? progressInfos : photographLines

    if (!showCompleted) {
      return baseProgressInfos.filter(
        (info) => info.percentage < 100 || info.error || info.retrying
      )
    }
    return baseProgressInfos
  }

  const sortedProgressInfos = [...getFilteredProgressInfos()].sort((a, b) => {
    if (a.percentage < 100 && b.percentage === 100) return -1
    if (a.percentage === 100 && b.percentage < 100) return 1
    return b.percentage - a.percentage
  })

  const clearPhotographFilters = () => {
    if (store.allImagesUploaded()) {
      setPhotographLines(progressInfos)
    } else return
  }
  const showPhotographsWithErrors = () => {
    if (store.allImagesUploaded()) {
      const _progressInfos = progressInfos
      setPhotographLines(_progressInfos.filter((pi) => pi.error))
    } else return
  }
  const showPhotographsWithoutErrors = () => {
    if (store.allImagesUploaded()) {
      const _progressInfos = progressInfos
      setPhotographLines(_progressInfos.filter((pi) => !pi.error))
    } else return
  }

  const estimatedTimeRemaining = store.getEstimatedTimeRemaining()

  // Get the average network speed from all progress infos that have network speed info
  const getAverageNetworkSpeed = () => {
    const speedInfos = progressInfos.filter((info) => info.networkSpeed?.speed)
    if (speedInfos.length === 0) return null

    const totalSpeed = speedInfos.reduce((acc, info) => acc + (info.networkSpeed?.speed || 0), 0)
    return {
      speed: Number((totalSpeed / speedInfos.length).toFixed(2)),
      classification: speedInfos[speedInfos.length - 1].networkSpeed?.classification || 'unknown',
    }
  }

  const averageNetworkSpeedInfo = getAverageNetworkSpeed()

  return (
    <>
      {store.imagesSubmitted && (
        <div
          className="w-full flex border-lumepic-medium_grey rounded-md mb-2 bg-bg_details"
          style={{ borderWidth: '1px' }}
        >
          <div
            className={`w-1/3 px-2 sm:px-3 pt-1 ${
              store.allImagesUploaded() &&
              'cursor-pointer hover:bg-lumepic-medium_grey transition-colors duration-200 active:bg-lumepic-semi_medium_grey'
            }`}
            onClick={clearPhotographFilters}
          >
            <div className="flex gap-2 items-center">
              <p className="mb-1 font-medium text-lg sm:text-xl">{t('Summary')}</p>
            </div>
            <div className="flex flex-col sm:flex-row">
              <div className="flex gap-1">
                <p className="mb-0 text-lumepic-grey">{totalProcessedCount}</p>
                <p className="mb-0 text-lumepic-grey">{t('of')}</p>
                <p className="mb-0 text-lumepic-grey">
                  {progressInfos.length} {t('files')}
                </p>
              </div>
              <span className="hidden sm:block text-lumepic-grey font-light sm:ml-1">{'| '}</span>
              <p className="mb-0 sm:ml-1 text-lumepic-grey">{formatBits(totalUploadSize)}</p>
              <span className="hidden sm:block text-lumepic-grey font-light sm:ml-1">{'| '}</span>
              <p className="mb-0 sm:ml-1 text-lumepic-grey">
                {'('}
                {((totalProcessedCount / progressInfos.length) * 100).toFixed(0)}
                {'%)'}
              </p>
            </div>
          </div>
          <div
            className={`w-1/3 px-3 py-2 border-lumepic-medium border-l border-r ${
              store.allImagesUploaded() &&
              'cursor-pointer hover:bg-lumepic-medium_grey transition-colors duration-200 active:bg-lumepic-semi_medium_grey'
            }`}
            onClick={showPhotographsWithoutErrors}
          >
            <p className="mb-1 font-medium text-lumepic-grey">{t('Uploaded successfully')}</p>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
              <FontAwesomeIcon icon={faCircleCheck} className="text-success hidden sm:block" />
              <span className="text-success text-sm">
                {uploadedSuccessfullyCount} {t('files')}
              </span>
              <span className="hidden sm:block text-success font-light">{'| '}</span>
              <span className="text-success text-sm">{formatBits(uploadedSuccessfullySize)}</span>
              <span className="text-success text-sm">
                {'('}
                {((uploadedSuccessfullyCount / progressInfos.length) * 100).toFixed(0)}
                {'%)'}
              </span>
            </div>
          </div>
          <div
            className={`w-1/3 px-3 py-2 ${
              store.allImagesUploaded() &&
              'cursor-pointer hover:bg-lumepic-medium_grey transition-colors duration-200 active:bg-lumepic-semi_medium_grey'
            }`}
            onClick={showPhotographsWithErrors}
          >
            <p className="mb-1 font-medium text-lumepic-grey">{t('With errors!')}</p>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
              <FontAwesomeIcon icon={faCircleXmark} className="text-warning hidden sm:block" />
              <span className="text-warning text-sm">
                {uploadedWithErrorsCount} {t('files')}
              </span>
              <span className="hidden sm:block text-warning font-light">{'| '}</span>
              <span className="text-warning text-sm">{formatBits(uploadedWithErrorsSize)}</span>
              <span className="text-warning text-sm">
                {'('}
                {((uploadedWithErrorsCount / progressInfos.length) * 100).toFixed(0)}
                {'%)'}
              </span>
            </div>
          </div>
          {averageNetworkSpeedInfo && (
            <div className="w-1/3 px-3 py-2 border-lumepic-medium border-l">
              <p className="mb-1 font-medium text-lumepic-grey">{t('Average Network Speed')}</p>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className={`hidden sm:block ${
                    averageNetworkSpeedInfo.classification === 'critical'
                      ? 'text-red-500'
                      : averageNetworkSpeedInfo.classification === 'slow'
                      ? 'text-warning'
                      : averageNetworkSpeedInfo.classification === 'medium'
                      ? 'text-blue-500'
                      : 'text-success'
                  }`}
                />
                <span
                  className={`text-sm ${
                    averageNetworkSpeedInfo.classification === 'critical'
                      ? 'text-red-500'
                      : averageNetworkSpeedInfo.classification === 'slow'
                      ? 'text-warning'
                      : averageNetworkSpeedInfo.classification === 'medium'
                      ? 'text-blue-500'
                      : 'text-success'
                  }`}
                >
                  {getNetworkSpeedLabel(
                    averageNetworkSpeedInfo.classification,
                    averageNetworkSpeedInfo.speed
                  )}
                </span>
              </div>
              {progressInfos.length > 0 && progressInfos[progressInfos.length - 1].networkSpeed && (
                <div className="mt-2">
                  <p className="mb-1 font-medium text-lumepic-grey text-sm">{t('Current Speed')}</p>
                  <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className={`hidden sm:block ${
                        progressInfos[progressInfos.length - 1].networkSpeed?.classification ===
                        'critical'
                          ? 'text-red-500'
                          : progressInfos[progressInfos.length - 1].networkSpeed?.classification ===
                            'slow'
                          ? 'text-warning'
                          : progressInfos[progressInfos.length - 1].networkSpeed?.classification ===
                            'medium'
                          ? 'text-blue-500'
                          : 'text-success'
                      }`}
                    />
                    <span
                      className={`text-sm ${
                        progressInfos[progressInfos.length - 1].networkSpeed?.classification ===
                        'critical'
                          ? 'text-red-500'
                          : progressInfos[progressInfos.length - 1].networkSpeed?.classification ===
                            'slow'
                          ? 'text-warning'
                          : progressInfos[progressInfos.length - 1].networkSpeed?.classification ===
                            'medium'
                          ? 'text-blue-500'
                          : 'text-success'
                      }`}
                    >
                      {getNetworkSpeedLabel(
                        progressInfos[progressInfos.length - 1].networkSpeed?.classification,
                        progressInfos[progressInfos.length - 1].networkSpeed?.speed
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {!isOnline && (
        <p className="text-center">{t('Connection interrupted. Waiting for connection...')}</p>
      )}
      {estimatedTimeRemaining !== '0' && (
        <div className="w-full flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <span className="font-medium">{t('Time')}:</span>
            <span className="text-lumepic-grey">{store.getTimeElapsed()}</span>
            {store.getEstimatedTimeRemaining() !== '0s' && (
              <>
                <span className="text-lumepic-grey">|</span>
                <span className="font-medium">{t('Remaining')}:</span>
                <span className="text-lumepic-grey">{store.getEstimatedTimeRemaining()}</span>
              </>
            )}
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={showCompleted}
                onChange={(e) => setShowCompleted(e.target.checked)}
                color="primary"
                size="small"
              />
            }
            label={<span className="text-sm">{t('Show completed files')}</span>}
          />
        </div>
      )}
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 380 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth, padding: '6px' }}>
                    {t(column.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedProgressInfos
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((progressInfo, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        if (column.id === 'name') {
                          return (
                            <TableCell
                              style={{ minWidth: column.minWidth, padding: '6px' }}
                              key={column.id}
                            >
                              {progressInfo.fileName}
                              {progressInfo.networkSpeed && (
                                <span className="ml-2 text-xs text-gray-500">
                                  {getNetworkSpeedLabel(
                                    progressInfo.networkSpeed.classification,
                                    progressInfo.networkSpeed.speed
                                  )}
                                </span>
                              )}
                            </TableCell>
                          )
                        } else if (column.id === 'extension') {
                          return (
                            <TableCell
                              style={{ minWidth: column.minWidth, padding: '6px' }}
                              key={column.id}
                            >
                              {progressInfo.type}
                            </TableCell>
                          )
                        } else if (column.id === 'size') {
                          return (
                            <TableCell
                              style={{ minWidth: column.minWidth, padding: '6px' }}
                              key={column.id}
                            >
                              {formatBits(progressInfo.size)}
                            </TableCell>
                          )
                        } else if (column.id === 'status') {
                          return (
                            <TableCell
                              style={{ minWidth: column.minWidth, padding: '6px' }}
                              key={column.id}
                            >
                              <div className="flex items-center">
                                {progressInfo.error ? (
                                  <div className="flex items-center">
                                    <FontAwesomeIcon
                                      icon={faCircleExclamation}
                                      className="text-red-500 mr-2"
                                    />
                                    <span className="text-sm text-red-500">
                                      {getErrorMessage(progressInfo)}
                                      {progressInfo.retryCount && progressInfo.retryCount > 0 && (
                                        <span className="ml-2 text-xs">
                                          ({t('Retries')}: {progressInfo.retryCount})
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                ) : progressInfo.percentage === 100 ? (
                                  <div className="flex items-center">
                                    <FontAwesomeIcon
                                      icon={faCircleCheck}
                                      className="text-green-500 mr-2"
                                    />
                                    <span className="text-sm text-green-500">{t('Complete')}</span>
                                  </div>
                                ) : (
                                  <div className="flex items-center">
                                    {!isOnline ? (
                                      <span className="text-sm text-yellow-500">
                                        {t('Waiting for connection...')}
                                      </span>
                                    ) : progressInfo.retrying ? (
                                      <div className="flex items-center">
                                        <FontAwesomeIcon
                                          icon={faSpinner}
                                          className="text-blue-500 mr-2 fa-spin"
                                        />
                                        <span className="text-sm text-blue-500">
                                          {t('Retrying...')} {progressInfo.percentage}%
                                        </span>
                                        <div className="flex-1 ml-2">
                                          <div className="h-2 bg-gray-200 rounded">
                                            <div
                                              className="h-2 bg-blue-500 rounded"
                                              style={{ width: `${progressInfo.percentage}%` }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="flex items-center space-x-2">
                                        <div className="flex-1">
                                          <div className="h-2 bg-gray-200 rounded">
                                            <div
                                              className="h-2 bg-blue-500 rounded"
                                              style={{ width: `${progressInfo.percentage}%` }}
                                            />
                                          </div>
                                        </div>
                                        <span className="text-sm text-gray-500">
                                          {progressInfo.percentage}%
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={progressInfosDisplayed.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          page={page}
          labelDisplayedRows={defaultLabelDisplayedRows}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '2px',
            },
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '2px',
            },
          }}
        />
      </Paper>
      {store.allImagesUploaded() && store.exceededRetries.length > 0 && (
        <div className="flex justify-end mt-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => store.retryFailedUploads(true)}
            startIcon={<FontAwesomeIcon icon={faSpinner} />}
            disabled={store.isLoading}
          >
            {store.isLoading ? t('Retrying...') : t('Retry All Failed Uploads')}
            {!store.isLoading && ` (${store.exceededRetries.length})`}
          </Button>
        </div>
      )}
      <UploadMessagesBanner />
      {store.hasImagesWithoutMetadata && (
        <div
          className="p-2 rounded-md bg-bg_details mt-2 flex items-center justify-between"
          style={{ borderWidth: '1px' }}
        >
          <div className="flex flex-col w-4/5">
            <span className="text-lumepic-light_black font-medium text-sm">
              {t('There are images without metadata, which can cause them to appear out of order.')}
            </span>
            <span className="text-xs mt-1.5 font-light">
              {t(
                'Metadata: Additional information that accompanies the image and provides details on various aspects related to the capture and content of the photo, such as date and time.'
              )}
            </span>
          </div>
          <div
            className="mx-2 cursor-pointer"
            onClick={() => store.changeHasImagesWithoutMetadata(false)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      )}
      {store.hasUploadedOversizedImages && (
        <div className="p-2 rounded-md bg-bg_details mt-2 flex items-center justify-between border">
          <div className="flex items-start gap-1.5 w-4/5">
            <FontAwesomeIcon icon={faCircleExclamation} className="text-warning text-sm mt-1" />
            <span className="text-lumepic-light_black font-medium text-sm w-full">
              {t('Some images are larger than 15MB and could not be uploaded. Try smaller files.')}
            </span>
          </div>
          <div
            className="mx-2 mb-1 cursor-pointer"
            onClick={() => store.changeHasUploadedOversizedImages(false)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ImageUploadTable)
