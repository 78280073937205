import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import AlbumService from '../../../../../services/AlbumService'
import InputStore from '../../../../../shared/store/InputStore'
import Album from '../../../../../shared/models/Album'
import { isNil } from 'lodash'
import FileService, { UploadError } from 'services/FileService'
import ConfigService, { ConfigKeys } from 'config'
import i18n from 'i18next'
import { NetworkSpeedClass, measureNetworkSpeed } from 'shared/util/networkSpeed'

const UPLOAD_BLOCK_SIZE = 6
const MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_EVENT_ALBUM = parseInt(
  ConfigService.getValue(ConfigKeys.EVENT_ALBUM_PHOTOGRAPHS_LIMIT)
)
const MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_INDEPENDENT_ALBUM = parseInt(
  ConfigService.getValue(ConfigKeys.INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT)
)

export type ProgressInfo = {
  fileName: string
  percentage: number
  requestSent: boolean
  error: boolean
  errorType?: 'network' | 'server' | 'permission' | 'unknown'
  errorMessage?: string
  size: number
  type: string
  uploadUrl?: string
  uploadKey?: string
  retryCount?: number
  retrying?: boolean
  networkSpeed?: {
    speed: number
    classification: NetworkSpeedClass
  }
}

interface WakeLockSentinel extends EventTarget {
  released: boolean
  type: 'screen'
  release(): Promise<void>
  onrelease: ((this: WakeLockSentinel, ev: Event) => any) | null
}

interface WakeLock {
  request(type: 'screen'): Promise<WakeLockSentinel>
}

declare global {
  interface Navigator {
    readonly wakeLock: WakeLock | undefined
  }
}

class UploadImageToAlbumStore {
  public isLoading: boolean
  public images: File[]
  public imagesSubmitted: boolean
  public progressInfos: ProgressInfo[]
  public uploadingImage: boolean
  public lastUploadedImageIndex: number | null
  public shouldInterrupt: boolean
  public error: any
  public name: InputStore<string>
  public serverError: string
  private albumService: AlbumService
  private fileService: FileService
  public waitingForConnection = false
  public hasImagesWithoutMetadata: boolean
  public hasUploadedOversizedImages: boolean
  private retryQueue: ProgressInfo[]
  public exceededRetries: ProgressInfo[]
  public eventAlbumPhotographsLimit: number
  public independentAlbumPhotographsLimit: number
  public maxPhotographs: number
  public uploadStartTime: number | null = null
  private lastTimeUpdate: number | null = null
  private wakeLock: WakeLockSentinel | null = null
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.albumService = new AlbumService()
    this.fileService = new FileService()
    this.retryQueue = []
    this.eventAlbumPhotographsLimit = MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_EVENT_ALBUM
    this.independentAlbumPhotographsLimit = MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_INDEPENDENT_ALBUM
  }

  reset() {
    this.isLoading = false
    this.images = []
    this.imagesSubmitted = false
    this.progressInfos = []
    this.uploadingImage = false
    this.lastUploadedImageIndex = null
    this.error = false
    this.hasImagesWithoutMetadata = false
    this.hasUploadedOversizedImages = false
    this.retryQueue = []
    this.exceededRetries = []
    this.eventAlbumPhotographsLimit = MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_EVENT_ALBUM
    this.independentAlbumPhotographsLimit = MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_INDEPENDENT_ALBUM
    this.uploadStartTime = null
    this.lastTimeUpdate = null
  }

  changeName(val: string) {
    this.name.setValue(val)
  }

  clearErrors() {
    this.name.clearError()
  }

  cancelBatchUpload(): void {
    this.shouldInterrupt = true
  }

  changeProgressInfos(val: ProgressInfo[]) {
    this.progressInfos = val
  }

  changeImagesSubmitted(val: boolean) {
    this.imagesSubmitted = val
  }

  changeImages(val: File[]) {
    this.images = val
  }

  changeHasImagesWithoutMetadata(val: boolean) {
    this.hasImagesWithoutMetadata = val
  }

  changeHasUploadedOversizedImages(val: boolean) {
    this.hasUploadedOversizedImages = val
  }

  uploadImageToAlbumStart() {
    this.error = null
    this.uploadingImage = true
  }

  getTimeElapsed(): string {
    if (!this.uploadStartTime) return '0s'

    const elapsedSeconds = Math.floor((Date.now() - this.uploadStartTime) / 1000)
    if (elapsedSeconds < 60) {
      return `${elapsedSeconds}s`
    } else if (elapsedSeconds < 3600) {
      const minutes = Math.floor(elapsedSeconds / 60)
      const seconds = elapsedSeconds % 60
      return `${minutes}m ${seconds}s`
    } else {
      const hours = Math.floor(elapsedSeconds / 3600)
      const minutes = Math.floor((elapsedSeconds % 3600) / 60)
      return `${hours}h ${minutes}m`
    }
  }

  getEstimatedTimeRemaining(): string {
    const completedUploads = this.progressInfos.filter(
      (info) => info.percentage === 100 && !info.error
    ).length
    const totalFiles = this.progressInfos.length

    if (
      completedUploads === 0 ||
      totalFiles === 0 ||
      !this.uploadStartTime ||
      completedUploads === totalFiles
    ) {
      return '0s'
    }

    // Need at least a few uploads to make a reasonable estimate
    if (completedUploads < 3) {
      return 'Calculating...'
    }

    const elapsedTime = (Date.now() - this.uploadStartTime) / 1000 // in seconds
    const averageTimePerUpload = elapsedTime / completedUploads
    const remainingUploads = totalFiles - completedUploads
    const estimatedRemainingTime = averageTimePerUpload * remainingUploads

    if (estimatedRemainingTime < 60) {
      const roundedSeconds = Math.ceil(estimatedRemainingTime / 10) * 10
      return `${roundedSeconds}s`
    } else if (estimatedRemainingTime < 3600) {
      const minutes = Math.floor(estimatedRemainingTime / 60)
      const seconds = Math.round(estimatedRemainingTime % 60)
      const roundedSeconds = Math.ceil(seconds / 10) * 10
      return roundedSeconds === 60 ? `${minutes + 1}m` : `${minutes}m ${roundedSeconds}s`
    } else {
      const hours = Math.floor(estimatedRemainingTime / 3600)
      const minutes = Math.round((estimatedRemainingTime % 3600) / 60)
      return `${hours}h ${minutes}m`
    }
  }

  uploadImageToAlbumFail(
    uploadedImageIndex: number,
    uploadUrl?: string,
    uploadKey?: string,
    error?: UploadError
  ) {
    const _progressInfos = [...this.progressInfos]
    _progressInfos[uploadedImageIndex].percentage = 100
    _progressInfos[uploadedImageIndex].uploadUrl = uploadUrl
    _progressInfos[uploadedImageIndex].uploadKey = uploadKey
    _progressInfos[uploadedImageIndex].retryCount =
      (_progressInfos[uploadedImageIndex].retryCount || 0) + 1

    if (error) {
      _progressInfos[uploadedImageIndex].errorType = error.type
      _progressInfos[uploadedImageIndex].errorMessage = error.message
      _progressInfos[uploadedImageIndex].networkSpeed = error.networkSpeed
    }

    const maxRetries =
      _progressInfos[uploadedImageIndex].networkSpeed?.classification === 'slow' ? 5 : 3

    if ((_progressInfos[uploadedImageIndex].retryCount ?? 0) > maxRetries) {
      _progressInfos[uploadedImageIndex].error = true
      _progressInfos[uploadedImageIndex].retrying = false
      _progressInfos[uploadedImageIndex].percentage = 0 // Reset progress since it failed
      this.exceededRetries.push(_progressInfos[uploadedImageIndex])
    } else {
      _progressInfos[uploadedImageIndex].error = false
      _progressInfos[uploadedImageIndex].retrying = true
      _progressInfos[uploadedImageIndex].percentage = 0 // Reset progress for retry
      this.retryQueue.push(_progressInfos[uploadedImageIndex])
    }

    this.changeProgressInfos(_progressInfos)
    this.error = null
    this.uploadingImage = false
    this.lastUploadedImageIndex = uploadedImageIndex
    if (this.allImagesUploaded()) {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  allImagesUploaded() {
    return (
      this.imagesSubmitted &&
      this.progressInfos &&
      this.progressInfos.filter((pi) => {
        // Consider an image not uploaded if it's not at 100% OR has an error OR is retrying
        return pi.percentage !== 100 || pi.error || pi.retrying
      }).length === 0
    )
  }

  private buildUploadBlocks(): File[][] {
    const numberOfBlocks = Math.ceil(this.images.length / UPLOAD_BLOCK_SIZE)

    const blocks = Array(numberOfBlocks)
    for (let i = 0; i < this.images.length; i += UPLOAD_BLOCK_SIZE) {
      const chunk = this.images.slice(i, i + UPLOAD_BLOCK_SIZE)
      const blockIndex = Math.floor(i / UPLOAD_BLOCK_SIZE)
      blocks[blockIndex] = chunk
    }
    return blocks
  }

  private async processRetryQueue() {
    while (this.retryQueue.length > 0) {
      const uploadInfo = this.retryQueue.shift()!
      if (!uploadInfo) continue

      const image = this.images.find((img) => img.name === uploadInfo.fileName)
      if (!image) continue

      const index = this.images.indexOf(image)
      let shouldRetry = true
      const retryCount = uploadInfo.retryCount || 0

      try {
        // Add delay before retry based on retry count
        const baseDelay = uploadInfo.networkSpeed?.classification === 'slow' ? 2000 : 1000
        const retryDelay = Math.min(baseDelay * Math.pow(2, retryCount), 10000)
        await new Promise((resolve) => setTimeout(resolve, retryDelay))

        // Update network speed before retry
        const networkSpeedInfo = await measureNetworkSpeed()
        runInAction(() => {
          const _progressInfos = [...this.progressInfos]
          _progressInfos[index].networkSpeed = {
            speed: networkSpeedInfo.speed,
            classification: networkSpeedInfo.classification,
          }
          _progressInfos[index].percentage = 0
          _progressInfos[index].error = false
          _progressInfos[index].retrying = true
          _progressInfos[index].retryCount = retryCount + 1
          this.changeProgressInfos(_progressInfos)
        })

        const result = await this.fileService.uploadFileToUrl(
          image,
          uploadInfo.uploadUrl!,
          uploadInfo.uploadKey!,
          (event: any) => {
            runInAction(() => {
              const _progressInfos = [...this.progressInfos]
              _progressInfos[index].percentage = Math.round((100 * event.loaded) / event.total)
              _progressInfos[index].requestSent = true
              this.changeProgressInfos(_progressInfos)
            })
          },
          async (error: UploadError) => {
            shouldRetry = false
            const maxRetries = error.networkSpeed?.classification === 'slow' ? 5 : 3

            runInAction(() => {
              const _progressInfos = [...this.progressInfos]
              _progressInfos[index].errorType = error.type
              _progressInfos[index].errorMessage = error.message
              _progressInfos[index].networkSpeed = error.networkSpeed
              _progressInfos[index].percentage = 0

              if (retryCount >= maxRetries) {
                _progressInfos[index].error = true
                _progressInfos[index].retrying = false
                this.exceededRetries.push(_progressInfos[index])
              } else {
                _progressInfos[index].error = false
                _progressInfos[index].retrying = true
                this.retryQueue.push(_progressInfos[index])
              }

              this.changeProgressInfos(_progressInfos)
            })
          }
        )

        if (result) {
          runInAction(() => {
            const _progressInfos = [...this.progressInfos]
            _progressInfos[index].percentage = 100
            _progressInfos[index].error = false
            _progressInfos[index].retrying = false
            this.changeProgressInfos(_progressInfos)
          })
        }
      } catch (e: any) {
        shouldRetry = false
        const networkSpeedInfo = await measureNetworkSpeed()
        const maxRetries = networkSpeedInfo.classification === 'slow' ? 5 : 3

        runInAction(() => {
          const _progressInfos = [...this.progressInfos]
          _progressInfos[index].networkSpeed = networkSpeedInfo
          _progressInfos[index].errorType = 'unknown'
          _progressInfos[index].errorMessage = e.message || 'Unknown error occurred during retry'
          _progressInfos[index].percentage = 0

          if (retryCount >= maxRetries) {
            _progressInfos[index].error = true
            _progressInfos[index].retrying = false
            this.exceededRetries.push(_progressInfos[index])
          } else {
            _progressInfos[index].error = false
            _progressInfos[index].retrying = true
            this.retryQueue.push(_progressInfos[index])
          }

          this.changeProgressInfos(_progressInfos)
        })
      }
    }
  }

  private async uploadSingleFile(
    image: File,
    uploadUrl: string,
    uploadKey: string,
    index: number,
    retryCount = 0
  ): Promise<boolean> {
    const networkSpeedInfo = await measureNetworkSpeed()
    const maxRetries = networkSpeedInfo.classification === 'slow' ? 5 : 3

    // Update progress info
    runInAction(() => {
      const _progressInfos = [...this.progressInfos]
      _progressInfos[index].networkSpeed = networkSpeedInfo
      _progressInfos[index].percentage = 0
      _progressInfos[index].retryCount = retryCount
      _progressInfos[index].error = false
      _progressInfos[index].retrying = retryCount > 0
      _progressInfos[index].errorMessage = retryCount > 0 ? `Retry ${retryCount}/${maxRetries}` : ''
      this.changeProgressInfos(_progressInfos)
    })

    try {
      await this.fileService.uploadFileToUrl(
        image,
        uploadUrl,
        uploadKey,
        (event: any) => {
          runInAction(() => {
            const _progressInfos = [...this.progressInfos]
            _progressInfos[index].percentage = Math.round((100 * event.loaded) / event.total)
            _progressInfos[index].errorMessage =
              retryCount > 0
                ? `Retry ${retryCount}/${maxRetries} - ${_progressInfos[index].percentage}%`
                : ''
            this.changeProgressInfos(_progressInfos)
          })
        },
        (error: UploadError) => {
          throw error
        }
      )

      // Upload succeeded
      runInAction(() => {
        const _progressInfos = [...this.progressInfos]
        _progressInfos[index].percentage = 100
        _progressInfos[index].error = false
        _progressInfos[index].retrying = false
        _progressInfos[index].errorMessage = '' // Clear retry message on success
        this.changeProgressInfos(_progressInfos)
      })
      return true
    } catch (error: any) {
      // If we haven't exceeded max retries, try again after delay
      if (retryCount < maxRetries) {
        const delay = Math.min(2000 * Math.pow(2, retryCount), 10000)
        await new Promise((resolve) => setTimeout(resolve, delay))
        return this.uploadSingleFile(image, uploadUrl, uploadKey, index, retryCount + 1)
      }

      // Max retries exceeded, mark as failed
      runInAction(() => {
        const _progressInfos = [...this.progressInfos]
        _progressInfos[index].percentage = 0
        _progressInfos[index].error = true
        _progressInfos[index].retrying = false
        _progressInfos[index].errorType = error.type || 'unknown'
        _progressInfos[index].errorMessage = `Failed after ${maxRetries} retries: ${
          error.message || 'Upload failed'
        }`
        this.exceededRetries.push(_progressInfos[index])
        this.changeProgressInfos(_progressInfos)
      })
      return false
    }
  }

  private async acquireWakeLock() {
    try {
      const wakeLock = 'wakeLock' in navigator ? (navigator as any).wakeLock : null
      if (wakeLock) {
        this.wakeLock = await wakeLock.request('screen')
      }
    } catch (err) {
      console.log('Wake Lock error:', err)
    }
  }

  private async releaseWakeLock() {
    if (this.wakeLock) {
      try {
        await this.wakeLock.release()
        this.wakeLock = null
      } catch (err) {
        console.log('Wake Lock release error:', err)
      }
    }
  }

  async batchUpload(album: Album): Promise<void> {
    runInAction(() => {
      this.isLoading = true
      this.shouldInterrupt = false
      this.uploadStartTime = Date.now()
      this.lastTimeUpdate = Date.now()
      this.exceededRetries = []
    })

    await this.acquireWakeLock()

    try {
      const blocks = this.buildUploadBlocks()

      for (const block of blocks) {
        if (this.shouldInterrupt) {
          runInAction(() => {
            this.shouldInterrupt = false
            this.isLoading = false
          })
          await this.releaseWakeLock()
          return
        }

        try {
          // Get upload URLs for the block
          const response = await this.albumService.batchCreatePhotographs(
            block,
            album.id,
            this.authStore.getToken()
          )

          if (response.hasImagesWithoutMetadata) {
            runInAction(() => {
              this.hasImagesWithoutMetadata = true
            })
          }

          // Upload each file in the block
          const uploadPromises = block.map(async (image) => {
            const index = this.images.indexOf(image)
            const uploadInfo = response.data.find(
              (info) => info.photograph.originalFileName === image.name
            )

            if (uploadInfo) {
              await this.uploadSingleFile(image, uploadInfo.uploadUrl, uploadInfo.uploadKey, index)
            }
          })

          await Promise.all(uploadPromises)
        } catch (e: any) {
          runInAction(() => {
            this.serverError = 'Failed to get upload URLs. Please try again.'
          })
        }
      }
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
      await this.releaseWakeLock()
    }
  }

  async retryFailedUploads(manualRetry: boolean) {
    if (!manualRetry || this.exceededRetries.length === 0) return

    runInAction(() => {
      this.isLoading = true
    })

    const retryPromises = this.exceededRetries.map(async (failedUpload) => {
      const image = this.images.find((img) => img.name === failedUpload.fileName)
      if (!image) return

      const index = this.images.indexOf(image)
      await this.uploadSingleFile(image, failedUpload.uploadUrl!, failedUpload.uploadKey!, index)
    })

    await Promise.all(retryPromises)

    runInAction(() => {
      this.exceededRetries = []
      this.isLoading = false
    })
  }

  findFilesWithLessThanThreeRetries(): number {
    const progressInfos = this.exceededRetries.filter((pi) => {
      return !isNil(pi.retryCount) && pi.retryCount < 4
    })
    return progressInfos.length
  }

  async retrySingleUpload(progressInfo: ProgressInfo) {
    const image = this.images.find((img) => img.name === progressInfo.fileName)
    if (image) {
      const index = this.images.indexOf(image)
      try {
        await this.fileService.uploadFileToUrl(
          image,
          progressInfo.uploadUrl!,
          progressInfo.uploadKey!,
          (event: any) => {
            const _progressInfos = [...this.progressInfos]
            _progressInfos[index].percentage = Math.round((100 * event.loaded) / event.total)
            _progressInfos[index].requestSent = true
            _progressInfos[index].error = false
            _progressInfos[index].retrying = false
            this.changeProgressInfos(_progressInfos)
          },
          (error: UploadError) => {
            this.uploadImageToAlbumFail(
              index,
              progressInfo.uploadUrl,
              progressInfo.uploadKey,
              error
            )
          }
        )
        runInAction(() => {
          const removeIndex = this.exceededRetries.indexOf(progressInfo)
          if (removeIndex > -1) {
            this.exceededRetries.splice(removeIndex, 1)
          }
        })
      } catch (e: any) {
        runInAction(() => {
          this.uploadImageToAlbumFail(index, progressInfo.uploadUrl, progressInfo.uploadKey)
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }
        })
      }
    }
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'name':
          this.name.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }

  getCurrentUploadingImageIndex(): number {
    let lastUploaded = 0
    for (let i = 0; i < this.progressInfos.length; i++) {
      if (this.progressInfos[i].percentage === 100) {
        lastUploaded = i
      }
    }
    return lastUploaded
  }
}

export default UploadImageToAlbumStore
