import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardContent, CardHeader } from '@mui/material'
import {
  faArrowRight,
  faChartLine,
  faCheckCircle,
  faEnvelope,
  faGift,
  faInfoCircle,
  faTicket,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { User } from 'shared/models/User'
import { formatDateShort } from 'shared/utility'
import StoresContext from '../../../providers/storesContext'
import MyRewardsStore from '../MyRewardsStore'
import { observer } from 'mobx-react-lite'
import Spinner from 'components/UI/Spinner'

interface RewardsSectionProps {
  totalRewards: number
  clients?: User[]
}

const RewardsSection = ({ totalRewards = 0, clients = [] }: RewardsSectionProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [myRewardsStore] = useState(() => new MyRewardsStore(authStore))

  useEffect(() => {
    myRewardsStore.fetchReferredUsers()
    myRewardsStore.fetchFidelityCodeEarnings()
    myRewardsStore.fetchReferralEarnings()
  }, [])

  const hasData =
    totalRewards > 0 || myRewardsStore.referredPhotographers.length > 0 || clients.length > 0

  return (
    <section className="space-y-8">
      {/* Info Card */}
      <Card className="bg-lumepic-white p-6 rounded-2xl shadow-lg mb-8">
        <CardHeader
          avatar={<FontAwesomeIcon icon={faInfoCircle} className="text-primary" size="2x" />}
          title={
            <span className="text-2xl font-extrabold text-lumepic-light_black">
              {hasData ? t('About Your Rewards') : t('Welcome to Your Rewards Dashboard')}
            </span>
          }
          className="flex items-center"
        />
        <CardContent>
          <p className="text-lumepic-grey leading-relaxed">
            {hasData
              ? t(
                  'Welcome to your rewards dashboard! Here you can track your earnings from referrals and fidelity code usage.'
                )
              : t(
                  'Start earning rewards by referring other photographers and encouraging your clients to use your unique fidelity code.'
                )}
            {t('The more your network grows, the more you earn!')}
          </p>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Rewards Section */}
        <Card className="bg-gradient-to-br from-lumepic-yellow to-primary p-6 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl">
          <CardHeader
            avatar={
              <FontAwesomeIcon icon={faGift} className="text-lumepic-light_black" size="2x" />
            }
            title={
              <span className="text-2xl font-extrabold text-lumepic-light_black">
                {t('Your Rewards')}
              </span>
            }
            className="flex items-center"
          />
          <CardContent className="bg-lumepic-white text-lumepic-light_black p-6 rounded-xl space-y-4">
            {hasData ? (
              <>
                <div className="flex items-center justify-between mb-6">
                  <span className="text-2xl font-semibold">{t('Total Rewards')}:</span>
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="mr-2 text-lumepic-success_dark_green"
                      size="2x"
                    />
                    <span className="text-4xl font-bold text-lumepic-success_dark_green">
                      ${totalRewards.toFixed(2)}
                    </span>
                  </div>
                </div>
                {myRewardsStore.referralEarnings > 0 && (
                  <div className="flex items-center justify-between pt-6 border-t border-primary-light">
                    <span className="text-lg font-semibold">{t('Available to Claim')}:</span>
                    <Button className="bg-success hover:bg-[#038f41] text-lumepic-white font-medium py-2 px-6 rounded-full transition-all duration-300 transform hover:scale-105 hover:shadow-lg flex items-center justify-center group">
                      <span className="flex items-center text-lg">
                        <FontAwesomeIcon
                          icon={faTicket}
                          className="mr-2 group-hover:animate-pulse"
                          size="2x"
                        />
                        {t('Claim')} ${myRewardsStore.referralEarnings.toFixed(2)} {t('Now')}
                      </span>
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <div className="text-center">
                <p className="text-lg font-semibold mb-2">{t('No rewards yet')}</p>
                <p className="text-lumepic-grey">
                  {t('Start referring photographers to earn rewards!')}
                </p>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Fidelity Code Earnings */}
        <Card className="bg-lumepic-light-grey p-6 rounded-2xl shadow-lg">
          <CardHeader
            avatar={
              <FontAwesomeIcon
                icon={faChartLine}
                className="text-lumepic-success_dark_green"
                size="2x"
              />
            }
            title={
              <span className="text-2xl font-extrabold text-lumepic-light_black">
                {t('Fidelity Code Earnings')}
              </span>
            }
            className="flex items-center"
          />
          <CardContent className="bg-lumepic-white p-6 rounded-xl shadow-md">
            {hasData ? (
              <>
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faTicket}
                    className="text-lumepic-success_dark_green mr-3"
                    size="2x"
                  />
                  <span className="text-4xl font-bold text-lumepic-success_dark_green">
                    ${myRewardsStore.fidelityCodeEarnings}
                  </span>
                </div>
                <p className="mt-2 text-lumepic-grey">
                  {t('Total earnings from commission-free sales')}
                </p>
              </>
            ) : (
              <div className="text-center">
                <p className="text-lg font-semibold mb-2">{t('No earnings yet')}</p>
                <p className="text-lumepic-grey">
                  {t('Share your fidelity code with clients to start earning!')}
                </p>
              </div>
            )}
          </CardContent>
        </Card>
      </div>

      {/* Referred Photographers */}
      <Card className="bg-lumepic-light-grey p-6 rounded-2xl shadow-lg">
        <CardHeader
          avatar={<FontAwesomeIcon icon={faUsers} className="text-primary" size="2x" />}
          title={
            <span className="text-xl font-extrabold text-lumepic-light_black">
              {t('Referred Photographers')}
            </span>
          }
          className="flex items-center"
        />
        <CardContent>
          <p className="mb-6 text-lumepic-grey text-sm">
            {t(
              'Refer other photographers and earn a percentage of their sales for one year from their sign-up date.'
            )}
          </p>
          {myRewardsStore.isLoading ? (
            <div className="bg-lumepic-white p-6 rounded-xl shadow-md text-center">
              <Spinner size={20} />
            </div>
          ) : myRewardsStore.referredPhotographers.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="w-full bg-lumepic-white rounded-xl shadow-md">
                <thead className="bg-primary text-lumepic-white">
                  <tr>
                    <th className="py-3 px-4 text-center">{t('Name')}</th>
                    <th className="py-3 px-4 text-center">{t('Alias')}</th>
                    <th className="py-3 px-4 text-center">{t('Total Earnings')}</th>
                    <th className="py-3 px-4 text-center">{t('Last Sale')}</th>
                    <th className="py-3 px-4 text-center">{t('Valid Period')}</th>
                  </tr>
                </thead>
                <tbody>
                  {myRewardsStore.referredPhotographers.map((photographer) => (
                    <tr
                      key={photographer.userId}
                      className="border-b border-primary-light hover:bg-lumepic-semi-medium-grey transition-colors duration-200"
                    >
                      <td className="py-3 px-4 text-lumepic-light_black text-center">
                        {photographer.firstName} {photographer.lastName}
                      </td>
                      <td className="py-3 px-4 text-lumepic-light_black text-center">
                        {photographer.alias}
                      </td>
                      <td className="py-3 px-4 text-lumepic-success_dark_green font-medium text-center">
                        ${photographer.totalEarningsInUsd.toFixed(2)}
                      </td>
                      <td className="py-3 px-4 text-lumepic-light_black text-center">
                        {photographer.lastSaleDate
                          ? formatDateShort(photographer.lastSaleDate)
                          : '-'}
                      </td>
                      <td className="py-3 px-4 text-lumepic-light_black text-center">
                        <div className="flex items-center justify-center">
                          <span>
                            {photographer.startDate ? formatDateShort(photographer.startDate) : '-'}{' '}
                            -{' '}
                            {photographer.endDate
                              ? formatDateShort(
                                  new Date(
                                    new Date(photographer.startDate).setFullYear(
                                      new Date(photographer.startDate).getFullYear() + 1
                                    )
                                  )
                                )
                              : '-'}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {myRewardsStore.totalPages > 1 && (
                <div className="mt-4 flex justify-between items-center px-4">
                  <div className="text-sm text-lumepic-grey">
                    {t('Showing')} {(myRewardsStore.page - 1) * myRewardsStore.itemsPerPage + 1}-
                    {Math.min(
                      myRewardsStore.page * myRewardsStore.itemsPerPage,
                      myRewardsStore.itemsCount
                    )}{' '}
                    {t('of')} {myRewardsStore.itemsCount}
                  </div>
                  <div className="flex gap-2">
                    <Button
                      disabled={!myRewardsStore.hasPreviousPage()}
                      onClick={() => myRewardsStore.setPage(myRewardsStore.page - 1)}
                      className="bg-primary hover:bg-primary-dark text-lumepic-white px-4 py-2 rounded-full disabled:opacity-50"
                    >
                      {t('Previous')}
                    </Button>
                    <Button
                      disabled={!myRewardsStore.hasNextPage()}
                      onClick={() => myRewardsStore.setPage(myRewardsStore.page + 1)}
                      className="bg-primary hover:bg-primary-dark text-lumepic-white px-4 py-2 rounded-full disabled:opacity-50"
                    >
                      {t('Next')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="bg-lumepic-white p-6 rounded-xl shadow-md text-center">
              <FontAwesomeIcon icon={faUsers} className="mx-auto mb-4 text-primary" size="4x" />
              <p className="text-lg font-semibold mb-2">{t('No referred photographers yet')}</p>
              <p className="text-lumepic-grey mb-4">
                {t('Start growing your network and earn more!')}
              </p>
              <Button className="bg-primary hover:bg-primary-dark text-lumepic-white font-medium py-2 px-6 rounded-full transition-all duration-300 flex items-center justify-center group">
                <span className="mr-2">{t('Invite Photographers')}</span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="group-hover:translate-x-1 transition-transform"
                  size="1x"
                />
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      {/* My Clients Section */}
      <Card className="bg-lumepic-light-grey p-6 rounded-2xl shadow-lg">
        <CardHeader
          avatar={<FontAwesomeIcon icon={faUsers} className="text-primary" size="2x" />}
          title={
            <span className="text-xl font-extrabold text-lumepic-light_black">
              {t('My Clients')}
            </span>
          }
          className="flex items-center"
        />
        <CardContent>
          <p className="mb-6 text-lumepic-grey text-sm">
            {t(
              'Customers who purchased using your fidelity code and therefore Lumepic will not charge you a commission for them for one year.'
            )}
          </p>
          {clients.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="w-full bg-lumepic-white rounded-xl shadow-md">
                <thead className="bg-primary text-lumepic-white">
                  <tr>
                    <th className="py-3 px-4 text-left">{t('Name')}</th>
                    <th className="py-3 px-4 text-left">{t('Alias')}</th>
                    <th className="py-3 px-4 text-left">{t('Valid Period')}</th>
                  </tr>
                </thead>
                <tbody>
                  {clients.map((client) => (
                    <tr
                      key={client.id}
                      className="border-b border-primary-light hover:bg-lumepic-semi-medium-grey transition-colors duration-200"
                    >
                      <td className="py-3 px-4 text-lumepic-light_black">
                        {client.firstName} {client.lastName}
                      </td>
                      <td className="py-3 px-4 text-lumepic-light_black">
                        <div className="flex items-center">{client.alias}</div>
                      </td>
                      {client.dateRegisteredAsClient && (
                        <td className="py-3 px-4 text-lumepic-light_black flex items-center">
                          {formatDateShort(client.dateRegisteredAsClient)} -{' '}
                          {formatDateShort(
                            new Date(
                              new Date(client.dateRegisteredAsClient).setFullYear(
                                new Date().getFullYear() + 1
                              )
                            )
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-lumepic-white p-6 rounded-xl shadow-md text-center">
              <FontAwesomeIcon icon={faEnvelope} className="mx-auto mb-4 text-primary" size="4x" />
              <p className="text-lg font-semibold mb-2">
                {t('No clients using your fidelity code yet')}
              </p>
              <p className="text-lumepic-grey mb-4">
                {t('Share your code with clients to get started!')}
              </p>
              <Button className="bg-primary hover:bg-primary-dark text-lumepic-white font-medium py-2 px-6 rounded-full transition-all duration-300 flex items-center justify-center group">
                <span className="mr-2">{t('Share Your Code')}</span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="group-hover:translate-x-1 transition-transform"
                  size="1x"
                />
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </section>
  )
}

export default observer(RewardsSection)
