import axiosInstance from './axios'
import axios, { AxiosResponse, isAxiosError } from 'axios'
import exifr from 'exifr'
import { measureNetworkSpeed, NetworkSpeedClass } from '../shared/util/networkSpeed'

export interface UploadError {
  type: 'network' | 'server' | 'permission' | 'unknown'
  message: string
  details?: any
  networkSpeed?: {
    speed: number
    classification: NetworkSpeedClass
  }
}

class FileService {
  public parseMetadata = async (
    file: File
  ): Promise<{ width: number; height: number; takenDate: Date; extraInfo: any } | null> => {
    const reader = new FileReader()
    if (file) {
      await reader.readAsDataURL(file)
      try {
        const info = await exifr.parse(file)
        const takenDate = info.DateTimeOriginal
        const width = info.ExifImageWidth
        const height = info.ExifImageHeight
        return {
          width,
          height,
          takenDate,
          extraInfo: info,
        }
      } catch (e) {
        console.error(JSON.stringify(e))
        return null
      }
    } else {
      throw new Error('Error extracting file. No file provided.')
    }
  }

  private async getRetryDelay(attempt: number, networkSpeed: NetworkSpeedClass): Promise<number> {
    // Exponential backoff with network speed consideration
    const baseDelay = networkSpeed === 'slow' ? 2000 : 1000
    return Math.min(baseDelay * Math.pow(2, attempt), 10000) // Max 10 seconds
  }

  async uploadFileToUrl(
    image: File,
    uploadUrl: string,
    uploadKey: string,
    onUploadProgress: (e: any) => void,
    onError: (e: UploadError) => void
  ): Promise<string> {
    const data = new FormData()
    data.append('file', image, `${uploadKey}`)

    try {
      // Check network speed before upload
      const networkSpeedInfo = await measureNetworkSpeed()

      const response = await axiosInstance({
        baseURL: uploadUrl,
        method: 'put',
        onUploadProgress,
        data: image,
        timeout: networkSpeedInfo.classification === 'slow' ? 60000 : 30000, // Longer timeout for slow connections
        headers: {
          'Content-Type': image.type,
        },
      })

      return response.data.message
    } catch (error: any) {
      const networkSpeedInfo = await measureNetworkSpeed()

      let uploadError: UploadError = {
        type: 'unknown',
        message: 'An unknown error occurred during upload',
        networkSpeed: {
          speed: networkSpeedInfo.speed,
          classification: networkSpeedInfo.classification,
        },
      }

      if (isAxiosError(error)) {
        if (!error.response) {
          uploadError = {
            type: 'network',
            message: 'Network error occurred. Please check your connection.',
            details: error.message,
            networkSpeed: {
              speed: networkSpeedInfo.speed,
              classification: networkSpeedInfo.classification,
            },
          }
        } else if (error.response.status === 403) {
          uploadError = {
            type: 'permission',
            message: 'Permission denied to upload file.',
            details: error.response.data,
            networkSpeed: {
              speed: networkSpeedInfo.speed,
              classification: networkSpeedInfo.classification,
            },
          }
        } else {
          uploadError = {
            type: 'server',
            message: 'Server error occurred during upload.',
            details: error.response.data,
            networkSpeed: {
              speed: networkSpeedInfo.speed,
              classification: networkSpeedInfo.classification,
            },
          }
        }
      }

      onError(uploadError)
      throw uploadError
    }
  }
}

export default FileService
