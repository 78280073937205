import React from 'react'
import PhotographersHeroSection from './components/HeroSection/PhotographersHeroSection'
import PhotographersBenefits from './components/Benefits/PhotographersBenefits'
import PhotographersHowItWorks from './components/HowItWorks/PhotographersHowItWorks'
import PhotographerFacts from './components/Facts/PhotographerFacts'
import PhotographersPricing from './components/Pricing/PhotographersPricing'
import PhotographerOtherUses from './components/Other/PhotographerOtherUses'
import PhotographerReviews from './components/Reviews/PhotographerReviews'
import PhotographerRegister from './components/Register/PhotographerRegister'
import Tracker from 'shared/tracking'
import AnimatedSection from './utils/AnimatedSection'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import axios from 'services/axios'

const PhotographersLanding = () => {
  React.useEffect(() => {
    Tracker.pageView('/photographers', 'PhotographersLanding')
  }, [])

  return (
    <>
      <AnimatedSection>
        <PhotographersHeroSection />
      </AnimatedSection>
      <AnimatedSection>
        <PhotographersBenefits />
      </AnimatedSection>
      <AnimatedSection>
        <PhotographersHowItWorks />
      </AnimatedSection>
      <AnimatedSection>
        <PhotographerFacts />
      </AnimatedSection>
      <AnimatedSection>
        <PhotographersPricing />
      </AnimatedSection>
      <AnimatedSection>
        <PhotographerOtherUses />
      </AnimatedSection>
      <AnimatedSection>
        <PhotographerReviews />
      </AnimatedSection>
      <AnimatedSection>
        <div id="photographer-register">
          <PhotographerRegister />
        </div>
      </AnimatedSection>
    </>
  )
}

export default withErrorHandler(PhotographersLanding, axios)
