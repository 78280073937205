import React from 'react'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faCalendar, faInfoCircle, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { PurchaseLineItem } from 'shared/models/PurchaseLineItem'
import { isNil } from 'lodash'

type SaleLineItemProps = {
  lineItem: PurchaseLineItem
}

const SaleLineItem = ({ lineItem }: SaleLineItemProps) => {
  const { t } = useTranslation()

  const parseAlbumTakenDate = (takenDate: Date) => {
    return moment.utc(takenDate).format('DD/MM/YYYY')
  }

  const desktopAlbumData = (
    <div className="hidden md:flex md:flex-col md:h-full md:justify-between">
      {!isNil(lineItem.album) && (
        <>
          <div className="hidden md:flex md:w-56 md:gap-0.5 md:text-lg md:font-semibold">
            <span>{`${t('Album from')} ${parseAlbumTakenDate(lineItem.album.takenDate)} `}</span>
          </div>
          <div className="hidden md:flex md:flex-col md:gap-4">
            <span className="text-sm flex items-center">
              <FontAwesomeIcon
                className="text-lg mr-1.5 text-lumepic-dark_grey"
                icon={faLocationDot}
              />
              <div className="truncate whitespace-nowrap">
                <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                  {lineItem.album.location.spotName?.toUpperCase()}{' '}
                </span>
              </div>
            </span>
            <span className="text-sm flex items-center">
              <FontAwesomeIcon className="text-lg mr-1.5 text-lumepic-dark_grey" icon={faBolt} />
              <div className="truncate whitespace-nowrap">
                <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                  {t(lineItem.album.activity.name)}
                </span>
              </div>
            </span>
            <span className="text-sm flex items-center">
              <FontAwesomeIcon
                className="text-lg mr-1.5 text-lumepic-dark_grey"
                icon={faInfoCircle}
              />
              <div className="truncate whitespace-nowrap">
                <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                  {t(lineItem.album.description)}
                </span>
              </div>
            </span>
            {!isNil(lineItem.album.event) && (
              <span className="text-sm flex items-center">
                <FontAwesomeIcon
                  className="text-lg mr-1.5 text-lumepic-dark_grey"
                  icon={faCalendar}
                />
                <div className="truncate whitespace-nowrap">
                  <span
                    className="text-sm pl-0.5 font-light text-lumepic-dark_grey whitespace-nowrap truncate"
                    style={{ maxWidth: 'calc(100% - 30px)' }}
                  >
                    {t(lineItem.album.event.name)}
                  </span>
                </div>
              </span>
            )}
          </div>
        </>
      )}
    </div>
  )

  return (
    <>
      {/* MOBILE */}
      <div className="flex flex-col md:hidden">
        {!isNil(lineItem.album) && (
          <div className="py-3">
            <span className="flex text-lg font-semibold">
              {t('Album from')} {''}
              {parseAlbumTakenDate(lineItem.album.createdAt)}
            </span>
            <div className="flex flex-col gap-2 pt-2">
              <span className="text-sm flex items-center">
                <FontAwesomeIcon
                  className="text-lg mr-1.5 text-lumepic-dark_grey"
                  icon={faLocationDot}
                />
                <div className="truncate whitespace-nowrap">
                  <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                    {lineItem.album.location.spotName?.toUpperCase()}
                  </span>
                </div>
              </span>
              <span className="text-sm flex items-center">
                <FontAwesomeIcon className="text-lg mr-1.5 text-lumepic-dark_grey" icon={faBolt} />
                <div className="truncate whitespace-nowrap">
                  <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                    {t(lineItem.album.activity.name)}
                  </span>
                </div>
              </span>
              <span className="text-sm flex items-center">
                <FontAwesomeIcon
                  className="text-lg mr-1.5 text-lumepic-dark_grey"
                  icon={faCalendar}
                />
                <div className="truncate whitespace-nowrap">
                  <span
                    className="text-sm pl-0.5 font-light text-lumepic-dark_grey whitespace-nowrap truncate"
                    style={{ maxWidth: 'calc(100% - 30px)' }}
                  >
                    {lineItem.album.description}
                  </span>
                </div>
              </span>
            </div>
          </div>
        )}
        {lineItem.photographs.map((photograph) => (
          <div key={lineItem.id} className="w-full">
            <img
              loading="lazy"
              className="w-full object-cover object-center"
              src={photograph.thumbnailUrl}
              alt="Photograph"
            />
            <span className="w-full justify-end text-base md:font-semibold">
              ${photograph.price}
            </span>
          </div>
        ))}
      </div>

      {/* DESKTOP */}
      <div className="hidden md:flex md:w-full md:py-3">
        {lineItem.photographs.map((photograph) => (
          <div key={lineItem.id} className="w-full flex justify-between">
            <img
              loading="lazy"
              className="max-w-36 max-h-48 object-cover object-center rounded-lg"
              src={photograph.thumbnailUrl}
              alt="Photograph"
            />
            <div className="pl-3">{desktopAlbumData}</div>
            <div className="w-full flex justify-end">
              <span className="text-base md:font-semibold">${photograph.price}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default observer(SaleLineItem)
