const prefix = '/users'
export const USERS = `${prefix}`
export const PHOTOGRAPHERS_USERS = `${prefix}/photographers`

export const suspendUserById = (userId: string) => {
  return `${prefix}/${userId}/suspend`
}
export const activateUserById = (userId: string) => {
  return `${prefix}/${userId}/activate`
}
export const createProfilePicture = (userId: string) => {
  return `${prefix}/${userId}/profile-picture/upload-url`
}
export const createPermanentRecognitionPicture = (userId: string) => {
  return `${prefix}/${userId}/permanent-recognition-picture/upload-url`
}
export const createTemporaryRecognitionPicture = `${prefix}/temporary-recognition-picture/upload-url`

export const deleteUserById = (userId: string) => {
  return `${prefix}/${userId}`
}

export const updateUserById = (userId: string) => {
  return `${prefix}/${userId}`
}

export const userLikedAlbumsById = (userId: string) => {
  return `${prefix}/${userId}/liked-albums`
}

export const userStarredAlbumsById = (userId: string) => {
  return `${prefix}/${userId}/starred-albums`
}

export const referredUserById = (id: string) => {
  return `${prefix}/referred/${id}/details`
}
