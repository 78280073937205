import React, { useContext, useEffect, useState } from 'react'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import axios from 'services/axios'
import Tracker from '../../shared/tracking'
import UserReviews from './components/UserReviews/UserReviews'
import Benefits from './components/Benefits/Benefits'
import StatsBar from './components/StatsBar'
import HowItWorksGuide from './components/HowItWorksGuide'
import PhotoGallery from './components/PhotoGallery/PhotoGallery'
import CallToAction from './components/CallToAction'
import HeroSection from './components/HeroSection/HeroSection'
import LoginRegisterStore from 'containers/Auth/LoginRegisterStore'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import StoresContext from '../../providers/storesContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { SetPageTitle } from '../../shared/utility'

const Home = () => {
  const history = useHistory()
  const { authStore, cartStore, locationStore } = useContext(StoresContext)!
  const { t } = useTranslation()

  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )

  const isAuthenticated = authStore.isAuthenticated()

  SetPageTitle('Home')
  const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    return loginRegisterStore.googleAuth(credentialResponse)
  }
  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  const goTo = (e: any, path: string) => {
    e.preventDefault()
    history.push(path)
  }

  useEffect(() => {
    Tracker.pageView('/', 'Home')
  }, [])

  return (
    <>
      <HeroSection goTo={goTo} isAuthenticated={isAuthenticated} />
      <Benefits />
      <PhotoGallery />
      <HowItWorksGuide />
      <StatsBar />
      <UserReviews />
      <CallToAction goTo={goTo} />
      {!authStore.isAuthenticated() && (
        <div className="hidden">
          <GoogleLogin
            size="large"
            width="150px"
            useOneTap
            onSuccess={googleLoginSuccess}
            onError={googleLoginError}
          />
        </div>
      )}
    </>
  )
}

export default withErrorHandler(observer(Home), axios)
