import { makeAutoObservable, runInAction } from 'mobx'
import { ReferredUserDetails } from './interfaces/ReferredPhotographerTable.interface'
import AuthStore from '../../stores/AuthStore'
import MyRewardsService from 'services/MyRewardsService'

export default class MyRewardsStore {
  public referredPhotographers: ReferredUserDetails[] = []
  public fidelityCodeEarnings: number
  public referralEarnings: number
  public isLoading = false
  public error: any = null
  public page = 1
  public totalPages = 1
  public itemsCount = 0
  public itemsPerPage = 10
  private readonly myRewardsService: MyRewardsService
  private readonly authStore: AuthStore

  constructor(authStore: AuthStore) {
    this.authStore = authStore
    this.myRewardsService = new MyRewardsService()
    this.myRewardsService = new MyRewardsService()
    makeAutoObservable(this)
  }

  reset() {
    this.referredPhotographers = []
    this.fidelityCodeEarnings = 0
    this.referralEarnings = 0
    this.isLoading = false
    this.error = null
    this.page = 1
    this.totalPages = 1
    this.itemsCount = 0
    this.itemsPerPage = 10
  }

  setPage(page: number) {
    if (page < 1) return
    if (page > this.totalPages) return

    this.page = page
    this.fetchReferredUsers()
  }

  async fetchReferredUsers() {
    this.startLoading()
    const token = this.authStore.getToken()
    const userId = this.authStore.getLoggedUser().id
    const limit = Math.max(1, Math.floor(this.itemsPerPage))
    const offset = Math.max(0, Math.floor((this.page - 1) * this.itemsPerPage))

    try {
      const response = await this.myRewardsService.getReferredPhotographers(
        userId,
        token,
        limit,
        offset
      )
      runInAction(() => {
        this.referredPhotographers = response.items.map((user) => ({
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          alias: user.alias,
          photographerRegisteredAt: user.photographerRegisteredAt,
          totalEarningsInUsd: user.totalEarningsInUsd,
          lastSaleDate: user.lastSaleDate,
          startDate: user.startDate,
          endDate: user.endDate,
        }))
        this.itemsCount = response.count
        this.totalPages = Math.ceil(response.count / this.itemsPerPage)
        this.stopLoading()
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.stopLoading()
      })
    }
  }

  async fetchFidelityCodeEarnings() {
    this.startLoading()
    const token = this.authStore.getToken()
    const userId = this.authStore.getLoggedUser().id
    try {
      const response = await this.myRewardsService.getFidelityCodeEarnings(userId, token)
      runInAction(() => {
        this.fidelityCodeEarnings = response.totalEarnings
        this.stopLoading()
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.stopLoading()
      })
    }
  }

  async fetchReferralEarnings() {
    this.startLoading()
    const token = this.authStore.getToken()
    const userId = this.authStore.getLoggedUser().id
    try {
      const response = await this.myRewardsService.getReferralEarnings(userId, token)
      runInAction(() => {
        this.referralEarnings = response.totalEarnings
        this.stopLoading()
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.stopLoading()
      })
    }
  }

  hasNextPage(): boolean {
    return this.page < this.totalPages
  }

  hasPreviousPage(): boolean {
    return this.page > 1
  }

  startLoading() {
    this.isLoading = true
  }

  stopLoading() {
    this.isLoading = false
  }
}
