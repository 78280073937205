import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import { ReferredUserDetails } from 'containers/MyRewards/interfaces/ReferredPhotographerTable.interface'
import { Paginated } from './RequestInterfaces/Paginated'
import FidelityCodeEarningsInterface from 'containers/MyRewards/interfaces/FidelityCodeEarnings.interface'
import ReferralEarningsInterface from 'containers/MyRewards/interfaces/ReferralEarnings.interface'

class MyRewardsService {
  async getReferredPhotographers(
    userId: string,
    token: string,
    limit: number,
    offset: number
  ): Promise<Paginated<ReferredUserDetails>> {
    return axios
      .get(paths.referredUserById(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pagination: {
            limit,
            skip: offset,
          },
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async getFidelityCodeEarnings(
    userId: string,
    token: string
  ): Promise<FidelityCodeEarningsInterface> {
    return axios
      .get(paths.fidelityCodeEarnings(userId), { headers: { Authorization: `Bearer ${token}` } })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async getReferralEarnings(userId: string, token: string): Promise<ReferralEarningsInterface> {
    return axios
      .get(paths.referralEarnings(userId), { headers: { Authorization: `Bearer ${token}` } })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default MyRewardsService
